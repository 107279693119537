<template>
<div id="screen-tv">
  <div id="header">
    <div class="logo">
      <img :src="'assets/logo.svg'" alt="">
    </div>
    <div class="time"><DigitalClock color="white"/></div>
  </div>
  <div id="calendar-view">
    <Calendar />
  </div>
  <div id="banner">
      <img src="https://cdn.terball.ru/storage/reception/reception-banner.jpg?ee" alt="">
  </div>
</div>
</template>
<script>
import Calendar from './components/Calendar.vue'
import DigitalClock from './components/DigitalClock.vue'
export default {
  name: 'app',
  components: {
    Calendar,
    DigitalClock
  }
}
</script>
<style>
body{
  background:#000;
  margin:0;
  padding:0;
}

#header{
  height: 13vh;
  border-bottom: 1px #444 solid;
  }
  .logo{
    float: left;
    padding: 1vw;
    }
    .logo img{
      padding-top:1vw;
      min-height: 3vw;
    }
  .time{
    padding-top:2.5vw;
    padding-right:1vw;
    float:right;
    font-weight: bold;
  }

#screen-tv{
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-size: 2vw;
  color:#fff;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  }

.customer-item{
   display: flex;
   align-items:center;
  }
  .customer-item-image{
    margin-right: 10px;
    display: flex;
    vertical-align: center;
    align-items:center;
    height:100%
  }
  .customer-item-title{
    line-height:100%;
  }
  .customer-versus{
    width:100px;
    text-align: center;
  }
  .customer-icon-vs{
    width: 30px;
    margin-right: 5px;
  }

#calendar-view{
  position: relative;
  width: 100%;
  overflow: hidden;
}



table{
  border:none;
  border-collapse: collapse;
  width: 100%;
}
table th{
  text-align:left;
  /* vertical-align: center; */
  padding-left: 10px;
  background: #112129;
  padding: 20px 0px 20px 10px;
}

table td{
  text-align:left;
  padding: 0;
  vertical-align: middle;
  padding: 10px 0px 10px 10px;
  border-bottom: solid 2px #444;
  line-height: 100%;
}
th.calendar-table-time{
  width: 15%;
  padding-left: 2vh;
}
th.calendar-table-coach{
  width: 70%;
}
th.calendar-table-court{
  width: 15%;
  text-align: center;
}


.table-court{
  white-space: nowrap;
  font-weight: bold;
  text-align: center;
}
.table-time{
  vertical-align: middle;
  padding-left: 2vh;
}
.customer-item-image{
  text-align: center;
}
.customer-item-image img{
  max-height: 5vw;
  border-radius: 50% !important;
}
.table-time{
  font-weight: bold;
  font-size: 80%;
  white-space:nowrap;
}

#banner {
  position: absolute;
  width: 100%;
  height: 20vh;
  left: 0;
  bottom: 0;
  }
  #banner img{
    width:100%;
  }



  </style>