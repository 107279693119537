<!-- <div class="customer-item-title"><strong>Евгений Невмержицкий: Волейбол</strong><br />Детская груповая тренировка. 13-17 лет. Средний уровень</div> -->

<template>
  <div class="calendar-container">
    <table class="calendar-table">
      <thead>
        <tr>
          <th class="calendar-table-time">Время</th>
          <th class="calendar-table-coach"></th>
          <th class="calendar-table-court">Площадка</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in items" v-bind:key="item.id">

          <td class="table-time">{{item.time_from}}-{{item.time_to}}</td>
          <td class="table-coach">

            <div v-if="item.type === 'request'" style="display: 1none;">
              <div class="customer-item">
                <div class="customer-item-image" style="display: 1none;"><img :src="item.customer.image" alt=""></div>
                <div class="customer-item-title" v-html="formatTitle(item)"></div>


              </div>
            </div>
          </td>
          <td class="table-court"><span v-html="item.rent.court.view.title"></span></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
  import axios from 'axios';
  export default {
    name: 'item-list',
    data() {
      return {
        items: null,
      };
    },
    methods: {
      updateData(){
         this.data = setInterval(() => {
          this.pollData()
        }, 60000)
      },
      formatTitle(item) {
        const pos = item.title.indexOf(':');
        if (pos !== -1) {
            const firstPart = item.title.substring(0, pos);
            const secondPart = item.title.substring(pos + 1);
            return `<strong>${firstPart}: ${item.sport}</strong><br />${secondPart}`;
        }
        return `<strong>${item.title}: ${item.sport}</strong>`;
      },
      pollData(){
        console.log('updated');
        axios
          .get('https://bdtm.space/api/v1/rent/screen?token=aDYeXpLQsUPHvk1M2ZPw0MJyNhiqexzkWuGKBG3ihRI')
          .then(res => {
            this.items = res.data;
          })
          .catch(function (error) {
            if (error.response) {
              // Запрос был сделан, и сервер ответил кодом состояния, который
              // выходит за пределы 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // Запрос был сделан, но ответ не получен
              // `error.request`- это экземпляр XMLHttpRequest в браузере и экземпляр
              // http.ClientRequest в node.js
              console.log(error.request);
            } else {
              // Произошло что-то при настройке запроса, вызвавшее ошибку
              console.log('Error', error.message);
            }
            console.log(error.config);
          })
      }

    },
    beforeUnmount() {
      clearInterval(this.items)
    },
    created() {
      this.pollData()
      this.updateData()
    }
  }
</script>